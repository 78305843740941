<template>
  <v-footer color="primary lighten-2" padless absolute>
    <v-row justify="center" no-gutters>
      <v-btn
        v-for="(link, idx) in links"
        :key="idx"
        small
        text
        color="white"
        rounded
        class="mt-1 no-active"
        :to="{ name: link.target }"
      >
        {{ link.label }}
      </v-btn>
      <v-col
        class="primary lighten-2 py-2 text-center white--text body-2 text-caption"
        cols="12"
      >
        Built and run with TLC by Folsom CAPS volunteers &bull; &copy;
        {{ new Date().getFullYear() }} Folsom Police Department
        <span v-if="devModeBanner"><br />build {{ build }}</span>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { get } from "vuex-pathify"
import { routeNames } from "./../router/config"

export default {
  name: "AppFooter",
  data: () => ({
    links: [
      { label: "Home", target: routeNames.home },
      { label: "About", target: routeNames.about },
      { label: "FAQ", target: routeNames.faq },
      { label: "Privacy Policy", target: routeNames.privacy }
    ],
    build: process.env.VUE_APP_BUILD_TIMESTAMP
  }),
  computed: {
    devModeBanner: get("appSettings/devModeBanner")
  }
}
</script>
