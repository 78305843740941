const routeNames = {
  home: "home",
  ohno: "ohno",
  privacy: "privacy",
  about: "about",
  login: "login",
  faq: "faq",
  unauthorized: "unauthorized",
  tree: "tree",
  santaslist: "santaslist",
  admin: "admin",
  adminHome: "admin-home",
  adminFamilies: "admin-families",
  adminFamily: "admin-family",
  adminChildren: "admin-children",
  adminChild: "admin-child",
  adminFAQ: "admin-faq",
  adminDonors: "admin-donors",
  adminDonor: "admin-donor",
  adminIndividualGifts: "admin-i-gifts",
  adminChildGifts: "admin-c-gifts",
  adminTree: "admin-tree",
  adminUsers: "admin-users",
  adminSettings: "admin-settings"
}

export { routeNames }
