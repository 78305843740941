import { analytics, EventName } from "./../services/firebase"

var lastTitle = ""

function getTitle(vm) {
  const { title } = vm.$options
  if (title) {
    return typeof title === "function" ? title.call(vm) : title
  }
}
export default {
  created() {
    const title = getTitle(this)
    if (title && title !== lastTitle) {
      lastTitle = title
      document.title = "Toy Drive - " + title
      analytics.logEvent(EventName.PAGE_VIEW)
      analytics.logEvent(EventName.SCREEN_VIEW)
    }
  }
}
