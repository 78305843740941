<template>
  <v-app
    id="app"
    :style="{ background: $vuetify.theme.themes[theme].background }"
  >
    <DevModeBanner v-if="devModeBanner" />
    <div
      :class="{
        bg: !isTreeView
      }"
    />
    <div :class="{ bg: isTreeView, tree: isTreeView }">
      <component :is="this.$route.meta.layout || 'div'">
        <router-view />
      </component>
    </div>
    <Confirm ref="confirm" />
    <Message ref="message" />
  </v-app>
</template>

<script>
// PROJECT
import Confirm from "./components/ConfirmDialog"
import Message from "./components/MessageDialog"
import DevModeBanner from "./components/DevModeBanner"
import { get } from "vuex-pathify"
import { routeNames } from "./router/config"
import { dispatch } from "vuex-pathify"

export default {
  name: "App",
  components: {
    Confirm,
    Message,
    DevModeBanner
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light"
    },
    isTreeView() {
      return this.$route.name === "tree" || this.$route.name === "admin-tree"
    },
    ohShoot: get("appSettings/ohShoot"),
    devModeBanner: get("appSettings/devModeBanner"),
    user: get("auth/user")
  },
  watch: {
    ohShoot: {
      handler(value) {
        if (value === true && this.$route.meta.redrectonOhNo) {
          this.$router.push({ name: routeNames.ohno }).catch((error) => {
            /* eslint-disable no-console */
            console.info(error.message)
            /* eslint-enable no-console */
          })
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open
    this.$root.$message = this.$refs.message.open
    dispatch("appSettings/load")
  }
}
</script>

<style lang="scss">
.bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-image: url("../public/img/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.v-application a {
  color: white !important;
}

@media only screen and (max-height: 320px) and (orientation: landscape) {
  .bg.tree {
    position: relative;
    background-position: -650px -50px;
    height: 100%;
  }
}
@media only screen and (min-height: 321px) and (orientation: landscape) {
  .bg.tree {
    position: relative;
    background-position: -1000px -50px;
    height: 100%;
  }
}
@media only screen and (min-height: 1024px) and (orientation: landscape) {
  .bg.tree {
    position: relative;
    background-position: -500px -50px;
    height: 100%;
  }
}
@media only screen and (min-height: 1366px) and (orientation: landscape) {
  .bg.tree {
    position: relative;
    background-position: -600px -50px;
    height: 100%;
  }
}
@media only screen and (max-height: 568px) and (orientation: portrait) {
  .bg.tree {
    position: relative;
    background-position: -900px -50px;
    height: 100%;
  }
}
@media only screen and (min-height: 626px) and (orientation: portrait) {
  .bg.tree {
    position: relative;
    background-position: -1000px -50px;
    height: 100%;
  }
}
@media only screen and (min-height: 635px) and (orientation: portrait) {
  .bg.tree {
    position: relative;
    background-position: -900px -50px;
    height: 100%;
  }
}
@media only screen and (min-height: 667px) and (orientation: portrait) {
  .bg.tree {
    position: relative;
    background-position: -900px -50px;
    height: 100%;
  }
}
@media only screen and (min-height: 736px) and (orientation: portrait) {
  .bg.tree {
    position: relative;
    background-position: -900px -50px;
    height: 100%;
  }
}
@media only screen and (min-height: 812px) and (orientation: portrait) {
  .bg.tree {
    position: relative;
    background-position: -1100px -50px;
    height: 100%;
  }
}
@media only screen and (min-height: 1812px) and (min-width: 375) and (orientation: portrait) {
  .bg.tree {
    position: relative;
    background-position: -900px -50px;
    height: 100%;
  }
}
.v-btn--active.no-active::before {
  opacity: 0 !important;
}
</style>
