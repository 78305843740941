// appOptions.js

const Role = {
  EDITOR: "editor",
  ADMIN: "admin",
  GIFT: "gift"
}

const RoleOptions = Object.values(Role)
Object.freeze(RoleOptions)
Object.freeze(Role)

// Shopping Status
const shoppingStatus = {
  shopping: "Shopping",
  finished: "Finished Shopping"
}

const shoppingStatusOptions = Object.values(shoppingStatus)

Object.freeze(shoppingStatus)
Object.freeze(shoppingStatusOptions)

// Year

const events = {
  ftd2020: "2020",
  ftd2021: "2021",
  ftd2022: "2022",
  ftd2023: "2023",
  ftd2024: "2024",
  ftd2025: "2025",
  ftd2026: "2026",
  ftp2027: "2027",
  ftp2028: "2028",
  ftp2029: "2029"
}

const eventOptions = Object.values(events)

Object.freeze(events)
Object.freeze(eventOptions)

// Selection Methods

const selectionMethods = {
  treeAndList: "Tree and List",
  treeOnly: "Tree Only",
  listOnly: "List Only"
}

const selectionMethodOptions = Object.values(selectionMethods)

Object.freeze(selectionMethods)
Object.freeze(selectionMethodOptions)

// Family Status
const familyStatus = {
  active: "Active",
  inactive: "Inactive"
}

const familyStatusOptions = Object.values(familyStatus)

Object.freeze(familyStatus)
Object.freeze(familyStatusOptions)

// Grades
const schoolGrades = {
  notInSchool: "Not yet in school",
  preK: "Pre K",
  k: "K",
  grade1: "1st",
  grade2: "2nd",
  grade3: "3rd",
  grade4: "4th",
  grade5: "5th",
  grade6: "6th",
  grade7: "7th",
  grade8: "8th",
  grade9: "9th",
  grade10: "10th",
  grade11: "11th",
  grade12: "12th"
}

const schoolGradeOptions = Object.values(schoolGrades)

Object.freeze(schoolGrades)
Object.freeze(schoolGradeOptions)

// Schools
const schools = {
  blancheSprentz: "Blanche Sprentz Elementary",
  carlSundahl: "Carl Sundahl Elementary",
  empireOaks: "Empire Oaks Elementary",
  folsomHigh: "Folsom High School",
  folsomHills: "Folsom Hills Elementary",
  folsomLake: "Folsom Lake High School",
  folsomMiddle: "Folsom Middle School",
  goldRidge: "Gold Ridge Elementary",
  natomaStation: "Natoma Station Elementary",
  oakChan: "Oak Chan Elementary",
  other: "Other",
  rusellRanch: "Russell Ranch Elementary",
  sandraJGallardo: "Sandra J Gallardo Elementary",
  sutterMiddle: "Sutter Middle School",
  theodoreJudah: "Theodore Judah Elementary",
  vistaDelLago: "Vista Del Lago High School"
}

const schoolOptions = Object.values(schools)

Object.freeze(schools)
Object.freeze(schoolOptions)

const analyticsEvents = {
  ADD_PAYMENT_INFO: "add_payment_info",
  ADD_SHIPPING_INFO: "add_shipping_info",
  ADD_TO_CART: "add_to_cart",
  ADD_TO_WISHLIST: "add_to_wishlist",
  BEGIN_CHECKOUT: "begin_checkout",
  CHECKOUT_PROGRESS: "checkout_progress",
  EXCEPTION: "exception",
  GENERATE_LEAD: "generate_lead",
  LOGIN: "login",
  PAGE_VIEW: "page_view",
  PURCHASE: "purchase",
  REFUND: "refund",
  REMOVE_FROM_CART: "remove_from_cart",
  SCREEN_VIEW: "screen_view",
  SEARCH: "search",
  SELECT_CONTENT: "select_content",
  SELECT_ITEM: "select_item",
  SELECT_PROMOTION: "select_promotion",
  SET_CHECKOUT_OPTION: "set_checkout_option",
  SHARE: "share",
  SIGN_UP: "sign_up",
  TIMING_COMPLETE: "timing_complete",
  VIEW_CART: "view_cart",
  VIEW_ITEM: "view_item",
  VIEW_ITEM_LIST: "view_item_list",
  VIEW_PROMOTION: "view_promotion",
  VIEW_SEARCH_RESULTS: "view_search_results"
}
const analyticsEventNames = Object.values(analyticsEvents)

Object.freeze(analyticsEvents)
Object.freeze(analyticsEventNames)

export {
  Role,
  shoppingStatus,
  shoppingStatusOptions,
  selectionMethodOptions,
  selectionMethods,
  events,
  eventOptions,
  familyStatus,
  familyStatusOptions,
  schoolGrades,
  schoolGradeOptions,
  schools,
  schoolOptions,
  analyticsEvents,
  analyticsEventNames
}
