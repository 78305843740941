// main.js

// NPM
import Vue from "vue"
import { firestorePlugin } from "vuefire"
import vuetify from "./plugins/vuetify"
import "core-js/stable"

// PROJECT
import App from "./App.vue"
import router from "./router"
import store from "./store"
import { auth, fetchUserClaims, logout } from "./services/firebase"
// Holding off on this for now...
import titleMixin from "./mixins/titleMixin"

// Tree Canvas component
import VueKonva from "vue-konva"

Vue.config.productionTip = false

Vue.use(VueKonva)
Vue.use(firestorePlugin)
Vue.mixin(titleMixin)

// alias
// Vue.prototype.$analytics = analytics

let app

auth.onAuthStateChanged((user) => {
  if (!app) {
    if (user) {
      fetchUserClaims(user).then(() => {
        //console.log("User Claims fetched")
        app = new Vue({
          el: "#app",
          components: { App },
          store,
          vuetify,
          router,
          render: (h) => h(App)
        }).$mount("#app")
      })
    } else {
      app = new Vue({
        el: "#app",
        components: { App },
        store,
        vuetify,
        router,
        render: (h) => h(App)
      }).$mount("#app")
    }
  }

  if (user) {
    fetchUserClaims(user)
  } else {
    logout()
  }
})

// async function writeUserInfoToStore(user) {
//   store.set("auth/user", user)
//   const claims = await fetchUserClaims(user)
//   store.set("auth/claims", claims)
// }
