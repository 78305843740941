<!-- **************************************************************************
     TEMPLATE
     ************************************************************************** -->

<template>
  <div v-if="isDev" class="dev-banner">DEV MODE {{ width }} x {{ height }}</div>
</template>

<!-- **************************************************************************
     SCRIPT
     ************************************************************************** -->

<script>
export default {
  name: "DevModeBanner",
  computed: {
    isDev: () => process.env.NODE_ENV === "development",
    width: () => window.innerWidth,
    height: () => window.innerHeight
  }
}
</script>

<!-- **************************************************************************
     STYLE
     ************************************************************************** -->

<style scoped lang="scss">
div {
  position: fixed;
  z-index: 1000;
  background-color: red;
  color: white;
  padding: 8px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: bold;
  font-size: 0.8rem;
  left: 50%;
  transform: translate(-50%, 0);
}
</style>
